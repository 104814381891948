.header-container {
  background-color: var(--dl-color-light-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

.logo-container {
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo-image {
  max-width: 100%;
}

.logo-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.logo-text {
  margin-top: 24px;
  font-family: "Geologica", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 40px;
  color: var(--dl-color-light-onbackground);
}

.logo-underline {
  width: 50px;
  margin-top: 12px;
  height: 1px;
  background-color: var(--dl-color-light-onbackground);
}

.sidebar-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.outline-text {
  padding: 40px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 23px;
  color: var(--dl-color-light-secondaryvariant);
}
  
@media screen and (max-width: 1200px) {
  .title-text {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 992px) {
  .logo-text {
    font-size: 1.4rem;
    margin-top: 12px;
  }
  .logo-underline {
    margin-top: 6px;
  }
}

@media screen and (max-width: 768px) {
  .app-container {
    height: auto;
    flex-direction: column;
  }
  .header-container {
    flex-direction: row;
    width: 100%;
  }
  .logo-container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
  }
  .logo-image {
    width: 60px;
    height: 60px;
  }
  .logo-text {
    margin-top: 0;
    font-size: 1rem;
  }
  .logo-underline {
    width: 30px;
    margin-top: 0;
  }
  .sidebar-navigation {
    display: none;
  }
  .outline-text {
    display: none;
  }
}