.first-section-container {
  flex: 3;
  background-color: var(--dl-color-light-surface);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 100px 0 0 100px;
  padding: 100px;
}

.title-text {
  font-family: 'Geologica', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 5.5rem;
  line-height: 100%;
  letter-spacing: -0.045em;
}

.first-section-content {
  display: flex;
  flex-direction: column;
}

.content-text {
  font-family: 'Geologica', serif;
  font-style: normal;
  font-weight: 100;
  font-size: 1.1rem;
  text-align: justify;
  color: var(--dl-color-light-onsurface);
}

.download-buttons-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}

.store-button {
  width: 150px;
}

.second-section-container {
  flex: 3;
  background-color: var(--dl-color-light-surface);
  padding: 10px;
}

.device-frame-image {
  border-radius: 80px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: #744AF6;
}

@media screen and (max-width: 1200px) {
  .title-text {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 992px) {
  .title-text {
    font-size: 3.3rem;
  }
  .content-text {
    font-size: 0.8rem;
  }
  .first-section-container {
    border-radius: 80px 0 0 80px;
  }
  .device-frame-image {
    border-radius: 60px;
  }
}

@media screen and (max-width: 768px) {
  .title-text {
    font-size: 2.2rem;
  }
  .first-section-container {
    border-radius: 60px 0 0 60px;
  }
  .content-text {
    font-size: 0.7rem;
  }
  .store-button {
    width: 100px;
  }
  .device-frame-image {
    border-radius: 40px;
  }
  @media screen and (max-width: 768px) {
    .landingpage-container {
      height: auto;
      flex-direction: column;
    }
    .first-section-container {
      border-radius: 60px 60px 0 0;
      padding: 40px;
    }
    .title-text {
      font-size: 4rem;
    }
    .download-text {
      font-size: 1rem;
    }
  }
}