:root {
  --dl-color-light-primary: rgba(31, 24, 192, 1);
  --dl-color-light-surface: rgba(244, 244, 244, 1);
  --dl-color-light-onprimary: rgba(255, 255, 255, 1);
  --dl-color-light-onsurface: rgba(0, 0, 0, 1);
  --dl-color-light-secondary: rgba(255, 255, 255, 0.30000001192092896);
  --dl-color-light-error: rgba(192, 31, 24, 1);
  --dl-color-light-background: rgba(255, 255, 255, 1);
  --dl-color-light-onsecondary: rgba(255, 255, 255, 1);
  --dl-color-light-onbackground: rgba(0, 0, 0, 1);
  --dl-color-light-secondaryvariant: rgba(0, 0, 0, 0.30000001192092896);
}
.input {
  color: var(--dl-color-light-onbackground);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: transparent;
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-light-background);
}

.app-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .app-container {
    min-height: 100vh;
    flex-direction: column;
  }
}