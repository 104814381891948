.markdown-container {
    width: 100%;
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: flex-start;
    padding: 100px;
    background-color: var(--dl-color-light-surface);
    border-radius: 100px 0 0 100px;
    overflow-y: scroll;
}

.markdown-container h1 {
    font-family: 'Geologica', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 5.5rem;
    line-height: 100%;
    letter-spacing: -0.045em;
    padding-bottom: 40px;
    color: var(--dl-color-light-onbackground)
}

.markdown-container h2 {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    color: var(--dl-color-light-onbackground);
    margin-bottom: 6px;
    margin-top: 12px;
}

.markdown-container h3 {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--dl-color-light-onbackground);
    margin-bottom: 6px;
    margin-top: 12px;
}

.markdown-container h4 {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    color: var(--dl-color-light-onbackground);
    margin-top: 12px;
    margin-bottom: 6px;
}

.markdown-container ul {
    padding: 8px 16px;
}

.markdown-container ol {
    padding: 8px 16px;
    margin: 0;
}

.markdown-container li {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 100;
    font-size: 1rem;
    text-align: left;
    color: var(--dl-color-light-onsurface);
    padding: 4px;
}

.markdown-container a {
    font-weight: 400;
    word-wrap: break-word;
}

.markdown-container p {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 100;
    font-size: 1rem;
    text-align: justify;
    color: var(--dl-color-light-onsurface);
}

@media screen and (max-width: 768px) {

    .markdown-container {
        padding: 40px;
        border-radius: 40px 40px 0 0;
        overflow: hidden;
    }

    .markdown-container h1 {
        padding-bottom: 24px;
        font-size: 4rem;
    }
}